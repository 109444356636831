// theme.js
export const theme = {
  text_color: "black",
  href: "#05b4ff",
  background_color: "#f0f0f0f0",
  link_color: "black",
  black: "rgba(0,0,0,0.95)",
  line1: "rgba(255,253,253, 0.3)",
  button: "rgba(16,16,16,0.59)",
  hover: "rgb(20, 102, 0)",
  mobile_max: "576px",
  tablet_max: "1100px",
  pc_min: "1101px",
};
