import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body {
    font-family: "Acumin", sans-serif;
    color: ${({ theme }) => theme.text_color};
    margin: 30px; 
  }
  
  body {
    background: ${({ theme }) => theme.background_color};
    font-size: 20px;
  }

  h1 {
    font-size: 50px; 
  }

  h2 {
    font-size: 20px;
    font-weight: normal; 
  }
  
  ul {
    list-style-type: none;
    line-height: 20px; 
}
  
  a {
    color: ${({ theme }) => theme.link_color};
    text-decoration: none;
  }

  projects {
    display: "flex";
    flex-direction: "row";
    flex-wrap: "wrap";
    background: ${({ theme }) => theme.white}
  }


`;
