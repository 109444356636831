import React, { useState, useEffect } from 'react';

const DynamicTitle = (data) => {
    console.log(data)
    const [titles, _] = useState(data["option_list"]);
    const [colors, __] = useState(data["colors_list"]);
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTitleIndex(currentIndex => (currentIndex + 1) % titles.length);
        }, 3000); // Change title every 3 seconds

        return () => clearInterval(interval);
    }, [titles]);

    return (
        <h1>
            {data["start"]}
            <span style={{ color: colors[currentTitleIndex] }}>{titles[currentTitleIndex]}</span>
            {data["end"]}
        </h1>
    );
};

export default DynamicTitle;