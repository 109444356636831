import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";

function Header() {

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div className="header" style={{ zIndex: 1, marginBottom: "50px" }}>
                <div className="header" style={{ zIndex: 1, display: "flex", justifyContent: "space-between" }}>
                    <div> CONSULTAI.CH </div>
                    <a href="mailto:sales@consultai.ch">CONTACT</a>
                </div>
                <div style={{ borderBottom: "1px solid black", marginTop: "25px" }}></div>
            </div>
        </ThemeProvider >
    );
}

export default Header;