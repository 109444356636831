import React from "react";
import { GlobalStyles } from "../styles/global";
import { theme } from "../styles/theme";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

import Header from "../components/navigation/Header";
import DynamicTitle from "../components/titles/DynamicTitle";


const textStyle = {
    backgroundColor: 'white',
    border: 'none',
    padding: '20px',
    fontSize: '24px',
    marginTop: '20px',
    display: 'inline-block',
};

const emailAddress = "sales@consultai.ch";


function Home() {


    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div>
                <Helmet>
                    <title>Home</title>
                    <meta name="description" content=" Experts in trustworthy, explainable, predictable AI." />
                </Helmet>

                <Header />

                <div className="middle" >

                    <DynamicTitle start="Your experts for "
                        option_list={["accurate", "interpretable", "robust", "scalable", "secure"]}
                        colors_list={["brown", "blue", "black", "green", "purple"]}
                        end=" AI." />

                    <h2 style={{ marginTop: "40px" }}> {"Connect with a network of technology and business experts to create value from data."} </h2>

                    <div style={textStyle}>{emailAddress} </div>
                </div>
            </div>


        </ThemeProvider >
    );
}
export default Home; 